import cn from "classnames";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";

import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import {
  useNotifyPreContract,
  usePreContractNotified,
} from "../../../features/quotes-funnel/application/draft-contract-use-cases";
import PetInfoForm, {
  PetInfoFormValues,
} from "../../../features/quotes-funnel/ui/organisms/pet-info-form/PetInfoForm";
import ShoppingCart from "../../../features/quotes-funnel/ui/organisms/shopping-cart/ShoppingCart";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import { useConfirmAndGoToCheckout } from "../../../features/quotes-funnel/utils/hooks/useConfirmResultAndGoToCheckout";
import InfoCard from "../../../molecules/info-card/InfoCard";
import PageTitle from "../../../molecules/page-title/PageTitle";
import {
  useResetSectionBeingEdited,
  useSectionBeingEdited,
  useSetSectionBeingEdited,
} from "../../../organisms/edit-info/edit-info-use-cases/edit-info-use-cases";
import { Result } from "../../../organisms/result/domain/result";
import { useResult, useUpdateResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { PetSpecies } from "../../../settings/pet";
import { Events, track } from "../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../utils/browser-features";
import { formatDate } from "../../../utils/date";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import {
  isIdDocumentRequired,
  shouldAllowFakeChipNumber,
  shouldNotifyPreContract,
} from "../../../utils/locale-configuration-utils";
import * as styles from "../../Details.module.scss";

const petSpeciesIcon: { [key in keyof typeof PetSpecies]: string } = {
  cat: "cat1",
  dog: "dog1",
};

export enum DetailsSectionName {
  pet = "pet",
  pet_parent = "pet_parent",
  chip = "chip",
  access = "access",
}

const Page = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const result = useResult() as Result;
  const resultPath = `/results/${result?.uuid}/`;
  const updateResult = useUpdateResult();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentURLSearchParams = getCurrentURLSearchParams();
  const isGlOnly = currentURLSearchParams?.get("gl_only");
  const noEditable = currentURLSearchParams?.get("no_editable") || isGlOnly;
  const sectionBeingEdited = useSectionBeingEdited();
  const setSectionBeingEdited = useSetSectionBeingEdited();
  const resetSectionBeingEdited = useResetSectionBeingEdited();
  const [hasComplianceErrorMessage, setHasComplianceErrorMessage] = useState<boolean>(false);
  const [hasPromotionCodeErrorMessage, setHasPromotionCodeErrorMessage] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [hasEditPetInfoWarning, setHasEditPetInfoWarning] = useState<boolean>(false);
  const hasFakeChipNumber: boolean = shouldAllowFakeChipNumber(result?.country);
  const [preContractNotified, setPreContractNotified] = usePreContractNotified();
  const notifyPreContract = useNotifyPreContract();
  const isRequiredPreContractNotification = shouldNotifyPreContract(result?.country);
  const petInfoFormRef = useRef<HTMLFormElement>(null);
  const confirmAndGoToCheckout = useConfirmAndGoToCheckout();

  useTrackPageViewed(Events.DETAILS_VIEWED_BROWSER);

  useEffect((): void => {
    if (result && isRequiredPreContractNotification && !preContractNotified) {
      const uuid = result?.uuid;

      void notifyPreContract(uuid).then(() => setPreContractNotified(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preContractNotified]);

  if (result && result.uncoveredReason && !isGlOnly) {
    void navigate(`/results/${result.uuid}/uncovered/`);

    return <></>;
  }

  const handleSubmit = async () => {
    if (!updateResult) return;

    setIsSubmitting(true);

    if (!result.isResultValidToCheckout) {
      await navigate(`/results/${result.uuid}/`);

      return;
    }

    const newResult = {
      pet_parent_commercial_accepted: result?.pet_parent_commercial_accepted ?? false,
      payment_interval_months: isGlOnly ? 12 : result?.payment_interval_months ?? 1,
    };

    try {
      await updateResult(newResult);
      await confirmAndGoToCheckout(
        result.uuid,
        result.country,
        setIsSubmitting,
        setHasSubmitError,
        setHasComplianceErrorMessage,
        setHasPromotionCodeErrorMessage,
        PageId.details
      );
    } catch (err) {
      handleUpdateResultErrors(err, "details");
      setIsSubmitting(false);
      setHasSubmitError(true);
    }
  };

  const petParentPersonalData = [
    {
      title: t("pet_parent.personal_data.first_name.field.title"),
      value: result?.pet_parent_first_name || "",
    },
    {
      title: t("pet_parent.personal_data.last_name.field.title"),
      value: result?.pet_parent_last_name || "",
      ...(hasComplianceErrorMessage && {
        errorMessage: parse(t("common.compliance_check_error_message")),
      }),
    },
    { title: t("pet_parent.address.title"), value: result?.pet_parent_address || "" },
    { title: t("pet_parent.address.zip.field.title"), value: result?.pet_parent_zip || "" },
    {
      title: t("pet_parent.access.country_code.field.title"),
      value: `+${result?.pet_parent_country_code || ""}`,
      variantName: "phoneCountryCode",
    },
    {
      title: t("pet_parent.access.phone.field.title"),
      value: result?.pet_parent_phone || "",
      variantName: "phoneNumber",
    },
  ];

  if (result && isIdDocumentRequired(result.country)) {
    petParentPersonalData.push({
      title: t("pet_parent.personal_data.id_number.field.title"),
      value: result.pet_parent_id_number,
    });
  }

  const handleEditInfo = (sectionName: DetailsSectionName): void => {
    setSectionBeingEdited(sectionName);
  };

  const handleCancelEditInfo = (): void => {
    resetSectionBeingEdited();
    setHasEditPetInfoWarning(false);
  };

  const handleSubmitEditInfo = (values: PetInfoFormValues): void => {
    track(Events.PET_INFO_EDITED, {
      initialValues: {
        petName: result.pet_name,
        petBreed: result.pet_breed.kb_key,
        petSex: result.pet_sex,
      },
      newValues: {
        petName: values.pet_name,
        petBreed: values.pet_breed_kb_key,
        petSex: values.pet_sex,
      },
      petNameChanged: values.pet_name !== result.pet_name,
      petBreedChanged: values.pet_breed_kb_key !== result.pet_breed.kb_key,
      petSexChanged: values.pet_sex !== result.pet_sex,
    });

    resetSectionBeingEdited();
    setHasEditPetInfoWarning(false);
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <section className={styles.detailsWrapper}>
            <PageTitle
              title={t("details.title")}
              subtitle={t("details.subtitle")}
              headingLevel={2}
            />

            {sectionBeingEdited === DetailsSectionName.pet ? (
              <PetInfoForm
                sectionName={DetailsSectionName.pet}
                adoptionClassName={cn(styles.petInfoForm, {
                  [styles.hasWarning]: hasEditPetInfoWarning,
                })}
                onEditCancelled={handleCancelEditInfo}
                onEditSubmitted={handleSubmitEditInfo}
                ref={petInfoFormRef}
              />
            ) : (
              <InfoCard
                sectionName={DetailsSectionName.pet}
                iconName={petSpeciesIcon[result.pet_species]}
                title={t("details.pet_data.title", { petName: result.pet_name })}
                list={[
                  { title: t("details.pet_data.name.field.title"), value: result.pet_name },
                  ...(isGlOnly
                    ? []
                    : [
                        {
                          title: t("common.birthdate"),
                          value: formatDate(result.pet_birth_date, i18n.language),
                        },
                      ]),
                  {
                    title: t("details.pet_data.breed.field.title"),
                    value: result.isCrossbreedPet
                      ? t("details.pet_data.breed.crossbreed", {
                          petBreed: result.pet_breed.name,
                          petSecondBreed: result.pet_second_breed?.name,
                        })
                      : result.pet_breed.name,
                  },
                  {
                    title: t("details.pet_data.sex.field.title"),
                    value: result.pet_sex && t(`common.pet_sex.${result.pet_sex}`),
                  },
                ]}
                {...(noEditable || result.isCrossbreedPet
                  ? {}
                  : {
                      editOptions: {
                        onEdit: () => handleEditInfo(DetailsSectionName.pet),
                        forceDisabled: sectionBeingEdited !== null,
                      },
                    })}
                adoptionClassName={styles.detailsInfoCard}
              />
            )}

            {!hasFakeChipNumber && (
              <InfoCard
                sectionName={DetailsSectionName.chip}
                iconName="chip"
                title={t("chip.chip.field.title")}
                list={[{ title: t("chip.chip.field.title"), value: result.pet_chip }]}
                {...(noEditable
                  ? {}
                  : {
                      editOptions: {
                        editPath: `${resultPath}chip/`,
                        forceDisabled: sectionBeingEdited !== null,
                      },
                    })}
                footnote={t("details.chip.footnote")}
                adoptionClassName={styles.detailsInfoCard}
              />
            )}

            <InfoCard
              sectionName={DetailsSectionName.pet_parent}
              iconName="single"
              title={t("pet_parent.title", { petName: result.pet_name })}
              list={petParentPersonalData}
              {...(noEditable
                ? {}
                : {
                    editOptions: {
                      editPath: `${resultPath}pet_parent/`,
                      forceDisabled: sectionBeingEdited !== null,
                    },
                  })}
              variantName="petParent"
              adoptionClassName={styles.detailsInfoCard}
            />

            <InfoCard
              sectionName={DetailsSectionName.access}
              iconName="key"
              title={t("pet_parent.access.title")}
              list={[
                {
                  title: t("pg_access.email"),
                  value: `${result.email}`,
                },
              ]}
              {...(noEditable
                ? {}
                : {
                    editOptions: {
                      editPath: `${resultPath}access/`,
                      forceDisabled: sectionBeingEdited !== null,
                    },
                  })}
              footnote={t("details.access.email.footnote")}
              adoptionClassName={styles.detailsInfoCard}
            />

            <form className={styles.confirmForm} onSubmit={handleSubmit}>
              <ShoppingCart
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                forceDisabledSubmit={sectionBeingEdited !== null}
                hasSubmitError={hasSubmitError}
                errorMessage={
                  hasPromotionCodeErrorMessage
                    ? t("common.promotion_code.error.prior_transactions")
                    : parse(t("common.error_message_with_email"))
                }
                petInfoFormRef={petInfoFormRef}
                setHasEditPetInfoWarning={setHasEditPetInfoWarning}
              />
            </form>
          </section>
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "", PageId.details);

export const query = graphql`
  query DetailsRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
