import { CalendarDate } from "@internationalized/date";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";
import * as Yup from "yup";

import { petService } from "../domain/pet/pet";
import { legalTimeZone } from "../utils/date";
import { FormikConfig } from "../utils/formik-types";

export interface UsePetBirthDateParams {
  initialBirthDate?: CalendarDate;
  petName?: string;
  minAgeValidation?: boolean;
}

export interface UsePetBirthDateFormValues {
  pet_birth_date?: CalendarDate;
}

export interface UsePetBirthDateValidatorViewModel extends FormikConfig<UsePetBirthDateFormValues> {
  tooYoungWarning?: string;
}

export const usePetBirthDateValidator = ({
  initialBirthDate,
  petName,
  minAgeValidation = true,
}: UsePetBirthDateParams): UsePetBirthDateValidatorViewModel => {
  const { isPetTooOld, isPetTooYoung } = petService();
  const { t } = useTranslation();
  const [tooYoungWarning, setTooYoungWarning] = useState<string | undefined>();

  const initialValues: UsePetBirthDateFormValues = {
    pet_birth_date: initialBirthDate,
  };

  const validateRequiredField = (value: CalendarDate | undefined, ctx: Yup.TestContext) => {
    if (!value) return ctx.createError({ message: t("common.validation.required") });
  };

  const validateFutureDate = (birthDate: Date, currentDate: Date, ctx: Yup.TestContext) => {
    if (birthDate >= currentDate) {
      return ctx.createError({ message: t("common.validation.valid_birth_date") });
    }
  };

  const validateMaxAge = (birthDate: Date, currentDate: Date, ctx: Yup.TestContext) => {
    if (isPetTooOld(birthDate, currentDate)) {
      return ctx.createError({ message: t("common.validation.pet_too_old") });
    }
  };

  const validateMinAge = (
    birthDate: Date,
    currentDate: Date,
    ctx: Yup.TestContext,
    shouldValidate: boolean
  ) => {
    if (!isPetTooYoung(birthDate, currentDate)) return;

    if (shouldValidate) {
      return ctx.createError({ message: t("common.validation.pet_too_young", { petName }) });
    }

    setTooYoungWarning(t("common.validation.pet_too_young_warning", { petName }));
  };

  const validationSchema = Yup.object({
    pet_birth_date: Yup.mixed().test({
      name: "validate-birth-date",
      test(value: CalendarDate, ctx) {
        setTooYoungWarning(undefined);

        const requiredError = validateRequiredField(value, ctx);
        if (requiredError) return requiredError;

        const birthDate = value.toDate(legalTimeZone);
        const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

        const futureDateError = validateFutureDate(birthDate, currentDate, ctx);
        if (futureDateError) return futureDateError;

        const maxAgeError = validateMaxAge(birthDate, currentDate, ctx);
        if (maxAgeError) return maxAgeError;

        const minAgeError = validateMinAge(birthDate, currentDate, ctx, minAgeValidation);
        if (minAgeError) return minAgeError;

        return true;
      },
    }),
  });

  return {
    initialValues,
    validationSchema,
    tooYoungWarning,
  };
};
