export interface PetAgeConstraints {
  minMonths: number;
  maxYears: number;
}

export const PET_AGE_CONSTRAINTS: PetAgeConstraints = {
  minMonths: 2,
  maxYears: 11,
} as const;

export interface PetAgeValidator {
  isValidPetAge: (birthDate: Date, referenceDate?: Date) => boolean;
  isPetTooYoung: (birthDate: Date, referenceDate?: Date) => boolean;
  isPetTooOld: (birthDate: Date, referenceDate?: Date) => boolean;
}

export const petService = (): PetAgeValidator => {
  const getMinPetAge = (referenceDate: Date): Date => {
    const minDate = new Date(referenceDate);
    minDate.setMonth(minDate.getMonth() - PET_AGE_CONSTRAINTS.minMonths);

    return minDate;
  };

  const getMaxPetAge = (referenceDate: Date): Date => {
    const maxDate = new Date(referenceDate);
    maxDate.setFullYear(maxDate.getFullYear() - PET_AGE_CONSTRAINTS.maxYears);

    return maxDate;
  };

  const isValidPetAge = (birthDate: Date, referenceDate: Date = new Date()): boolean => {
    const minAge = getMinPetAge(referenceDate);
    const maxAge = getMaxPetAge(referenceDate);

    return birthDate <= minAge && birthDate > maxAge;
  };

  const isPetTooYoung = (birthDate: Date, referenceDate: Date = new Date()): boolean => {
    const minAge = getMinPetAge(referenceDate);

    return birthDate > minAge;
  };

  const isPetTooOld = (birthDate: Date, referenceDate: Date = new Date()): boolean => {
    const maxAge = getMaxPetAge(referenceDate);

    return birthDate <= maxAge;
  };

  return {
    isValidPetAge,
    isPetTooYoung,
    isPetTooOld,
  };
};
