// extracted by mini-css-extract-plugin
export var birthDateErrorMessage = "PetInfoForm-module--birthDateErrorMessage--285ff";
export var birthDateWarningMessage = "PetInfoForm-module--birthDateWarningMessage--cb9ee";
export var birthdateWrapper = "PetInfoForm-module--birthdateWrapper--88d0d";
export var breedSelect = "PetInfoForm-module--breedSelect--3557a";
export var cancel = "PetInfoForm-module--cancel--a8013";
export var icon = "PetInfoForm-module--icon--26d62";
export var petInfoForm = "PetInfoForm-module--petInfoForm--1e6e9";
export var petName = "PetInfoForm-module--petName--4c132";
export var save = "PetInfoForm-module--save--a7376";
export var sexSelect = "PetInfoForm-module--sexSelect--ec1cb";
export var title = "PetInfoForm-module--title--56370";